<template>
  <div>

    <p class="mb-4 title">Versions</p>


    <v-card class="rounded-lg shadow"
            :loading="isLoading"
            :disabled="isLoading">

      <v-card-text class="py-5 px-5">

        <v-simple-table class="table-border rounded-lg my-5">
          <template v-slot:default>
            <thead>
            <tr>
              <th width="40%">Version</th>
              <th width="40%">Platform</th>
              <th width="40%">Force mise à jour</th>
              <th width="10%"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,i) in versions" :key="i">

              <td>
                <v-text-field dense
                              hide-details="auto"
                              outlined
                              placeholder="1.0.0"
                              v-model="item.version"
                ></v-text-field>
              </td>

              <td>
                <v-select dense
                          :items="['IOS','Android']"
                          hide-details="auto"
                          outlined
                          placeholder="IOS,Android"
                          v-model="item.platform"
                ></v-select>
              </td>

              <td>
                <v-switch color="success"
                          dense
                          hide-details
                          class="pa-0 ma-0"
                          v-model="item.force_update"></v-switch>
              </td>

              <td>
                <v-btn icon color="red" @click="versions.splice(i,1)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>

            </tr>
            <tr v-if="!versions.length">
              <td colspan="5" class="text-center">Aucun enregistrement trouvé!</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="text-center mb-5">
          <v-btn fab color="primary" depressed dark small @click="addItem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>

        <v-btn block color="primary" large depressed class="rounded-lg" @click="saveSetting">
          Enregistrer
        </v-btn>
      </v-card-text>

    </v-card>

  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: true,
      versions: []
    };
  },
  methods: {
    addItem() {
      this.versions.push({
        version: null,
        force_update: true,
        platform: 'Android',
      })
    },
    saveSetting() {
      this.isLoading = true
      HTTP.post("/v1/settings/update-versions", {
        versions: this.versions
      }).then(() => {
        this.isLoading = false
        this.$successMessage = "Mise a jour avec succès";
      }).catch((e) => {
        this.isLoading = false
      });
    },
    fetchData() {
      this.isLoading = true
      HTTP.get("/v1/settings").then((res) => {
        this.isLoading = false
        this.versions = res.data.data && res.data.data.versions ? res.data.data.versions : []
      }).catch((e) => {
        console.log(e);
        this.isLoading = false
      });
    },
  },
  mounted() {
    this.fetchData();
  },
}
</script>

<style scoped>
</style>