<template>
  <div>
    <p class="mb-4 title">Délai de paiement en ligne</p>
    <v-card class="rounded-lg shadow" :loading="isLoading" :disabled="isLoading">
      <v-card-text class="py-5 px-5">

        <v-text-field outlined label="Délai de paiement en ligne *" v-model="online_payment_time"></v-text-field>


        <v-btn block color="primary" large depressed class="rounded-lg" @click="saveData">
          Enregistrer
        </v-btn>

      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: true,
      online_payment_time: null,
    };
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      HTTP.get("/v1/settings")
          .then((res) => {
            this.online_payment_time = res.data.data.online_payment_time;
            this.isLoading = false;
          })
          .catch((e) => {
            console.log(e)
            this.isLoading = false;
          });
    },
    saveData() {
      this.isLoading = true;
      HTTP.post("/v1/settings/update-online-payment-time", {
        online_payment_time: parseInt(this.online_payment_time),
      }).then(() => {
        this.isLoading = false;
        this.$successMessage = "Le Délai de paiement en ligne a été mis à jour";
      }).catch(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>