<template>
  <div>

    <p class="mb-4 title">Méthodes vérification des utilisateurs</p>


    <v-card class="rounded-lg shadow"
            :loading="isLoading"
            :disabled="isLoading">

      <v-card-text class="py-5 px-5">

        <v-radio-group row v-model="user_verification_method">
          <v-radio label="Simple" value="simple"></v-radio>
          <v-radio label="Shufti" value="shufti"></v-radio>
        </v-radio-group>

        <v-btn block color="primary" large depressed class="rounded-lg" @click="saveSetting">
          Enregistrer
        </v-btn>
      </v-card-text>

    </v-card>

  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: true,
      user_verification_method: "simple"
    };
  },
  methods: {
    saveSetting() {
      this.isLoading = true
      HTTP.post("/v1/settings/update-user-verification-method", {
        user_verification_method: this.user_verification_method
      }).then(() => {
        this.isLoading = false
        this.$successMessage = "Mise a jour avec succès";
      }).catch((e) => {
        this.isLoading = false
      });
    },
    fetchData() {
      this.isLoading = true
      HTTP.get("/v1/settings").then((res) => {
        this.isLoading = false
        this.user_verification_method = res.data.data && res.data.data.user_verification_method ? res.data.data.user_verification_method : "simple"
      }).catch((e) => {
        console.log(e);
        this.isLoading = false
      });
    },
  },
  mounted() {
    this.fetchData();
  },
}
</script>

<style scoped>
</style>